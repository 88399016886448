var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "model-loading-overlay",
        { attrs: { model: _vm.personModel } },
        [
          _vm.currentUserIsAdmin || _vm.currentUserIsStaff
            ? _c(
                "b-button",
                { attrs: { variant: "link" }, on: { click: _vm.back } },
                [_vm._v("Back")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("person-summary"),
          _vm._v(" "),
          _vm.person
            ? _c(
                "b-tabs",
                {
                  attrs: { "content-class": "mt-3" },
                  on: { "activate-tab": _vm.handleTabActivation },
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "General", active: !_vm.tab, lazy: "" } },
                    [_c("person-details")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Demographics & Community",
                        active: _vm.tab === "other",
                        lazy: "",
                      },
                    },
                    [_c("person-demographics")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Availability",
                        active: _vm.tab === "availability",
                      },
                    },
                    [
                      _vm.person
                        ? _c("availability-and-interests", {
                            staticClass: "mb-5",
                            attrs: {
                              start_time: _vm.start_time,
                              end_time: _vm.end_time,
                              timezone: _vm.timezone,
                            },
                            model: {
                              value: _vm.person,
                              callback: function ($$v) {
                                _vm.person = $$v
                              },
                              expression: "person",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Session Selection",
                        active: _vm.tab === "session-selection",
                        lazy: "",
                      },
                    },
                    [
                      _vm.person && _vm.hasOpenForInterest
                        ? _c("session-selector", {
                            attrs: {
                              defaultSortBy: "sessions.title",
                              model: _vm.sessionModel,
                              defaultFilter:
                                '{"op":"all","queries":[["open_for_interest", "=", true]]}',
                            },
                            model: {
                              value: _vm.person,
                              callback: function ($$v) {
                                _vm.person = $$v
                              },
                              expression: "person",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hasOpenForInterest
                        ? _c("div", { staticClass: "container-fluid mt-5" }, [
                            _c(
                              "h5",
                              { staticClass: "font-italic text-muted" },
                              [_vm._v("Coming soon!")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Session Rankings",
                        active: _vm.tab === "session-ranking",
                        lazy: "",
                      },
                    },
                    [
                      _vm.person && _vm.hasOpenForInterest
                        ? _c("session-ranker", {
                            attrs: {
                              defaultSortBy:
                                "interest_ranking,session_assignments.updated_at",
                              defaultSortDesc: true,
                              perPage: null,
                              model: _vm.sessionAssignmentModel,
                              defaultFilter: _vm.rankedFilter,
                              person_id: _vm.person.id,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hasOpenForInterest
                        ? _c(
                            "div",
                            { staticClass: "container-fluid d-flex mt-5" },
                            [
                              _c(
                                "h5",
                                { staticClass: "font-italic text-muted" },
                                [_vm._v("Coming soon!")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentUserIsAdmin ||
                  _vm.currentUserIsStaff ||
                  _vm.firmSchedule
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.liveScheduleTitle,
                            lazy: "",
                            active: _vm.tab === "schedule",
                          },
                        },
                        [_c("person-live-schedule")],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.displayDraftSchedule
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            title: "Draft Schedule",
                            lazy: "",
                            active: _vm.tab === "draft-schedule",
                          },
                        },
                        [_c("person-draft-schedule")],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserIsAdmin || _vm.currentUserIsStaff
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            title: "Emails",
                            lazy: "",
                            active: _vm.tab === "email",
                          },
                        },
                        [_c("people-email-tab")],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserIsAdmin || _vm.currentUserIsStaff
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            title: "Admin",
                            lazy: "",
                            active: _vm.tab === "admin",
                          },
                        },
                        [_c("people-admin-tab")],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUserIsAdmin
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            title: "Integrations",
                            lazy: "",
                            active: _vm.tab === "integrations",
                          },
                        },
                        [
                          _c("pre", [
                            _vm._v(
                              _vm._s(
                                JSON.stringify(
                                  _vm.person.integrations,
                                  undefined,
                                  2
                                )
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-tab", {
                    attrs: { title: "Surveys", disabled: "", lazy: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }