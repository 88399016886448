var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("session-search", {
        attrs: { value: _vm.filter, columns: _vm.columns },
        on: { change: _vm.onSearchChanged },
      }),
      _vm._v(" "),
      _c("b-pagination", {
        staticClass: "d-flex justify-content-end",
        attrs: {
          "total-rows": _vm.totalRows,
          "per-page": _vm.perPage,
          "first-text": "First",
          "last-text": "Last",
          "prev-text": "Prev",
          "next-text": "Next",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
      _vm._v(" "),
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col-11" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-1" }, [
                _c("b", [_vm._v("Add to Interested")]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.sortedCollection, function (item) {
              return _c("div", { key: item.id, staticClass: "row mb-5" }, [
                _c("div", { staticClass: "col-11" }, [
                  _c("h4", [_vm._v(_vm._s(item.title))]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(item.description) },
                  }),
                  _vm._v(" "),
                  item.format
                    ? _c("div", [
                        _vm._v("\n            Format: "),
                        _c(
                          "span",
                          { staticClass: "badge badge-pill badge-info mr-1" },
                          [_vm._v(_vm._s(item.format.name))]
                        ),
                        _c("br"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.area_list.length > 0
                    ? _c(
                        "div",
                        [
                          _vm._v("\n            Area(s): "),
                          _vm._l(item.area_list, function (area) {
                            return _c(
                              "span",
                              {
                                key: area,
                                staticClass:
                                  "badge badge-pill badge-primary mr-1",
                              },
                              [_vm._v(_vm._s(area))]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.tag_list.length > 0
                    ? _c(
                        "div",
                        [
                          _vm._v("\n            Tag(s): "),
                          _vm._l(item.tag_list, function (tag) {
                            return _c(
                              "span",
                              {
                                key: tag,
                                staticClass:
                                  "badge badge-pill badge-secondary mr-1",
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.instructions_for_interest
                    ? _c("div", { staticClass: "mt-3" }, [
                        _vm._v("Instructions for potential panelists:"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "panelist-instructions",
                    domProps: {
                      innerHTML: _vm._s(item.instructions_for_interest),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-1" }, [
                  _vm.assignments
                    ? _c(
                        "div",
                        [
                          _c("interest-indicator", {
                            attrs: {
                              session: item,
                              person_id: _vm.person.id,
                              model: _vm.sessionAssignmentModel,
                              assignments: _vm.assignments,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("b-pagination", {
        staticClass: "d-flex justify-content-end",
        attrs: {
          "total-rows": _vm.totalRows,
          "per-page": _vm.perPage,
          "first-text": "First",
          "last-text": "Last",
          "prev-text": "Prev",
          "next-text": "Next",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n    To refine the sessions displayed, filter by area or do a word search (or both!) by clicking on the search button."
      ),
      _c("br"),
      _vm._v(
        "\n    Select sessions you are interested in being on by using the slider to the right of the description.\n    Your selections will appear on the Sessions Ranking tab.\n  "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }