var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sidebar-vue", {
    attrs: { width: "60%", model: "person" },
    scopedSlots: _vm._u(
      [
        _vm.selected
          ? {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-baseline" },
                    [
                      _c(
                        "b-link",
                        {
                          attrs: {
                            to: "/people/edit/".concat(_vm.selected.id),
                          },
                        },
                        [
                          _c("h3", { staticClass: "mr-auto" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.selected.published_name) +
                                "\n        "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-3" }, [
                        _vm._v("Rank: " + _vm._s(_vm.rank)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-3" }, [
                        _vm._v("Session moderation preference: "),
                        _c("span", { staticClass: "first-capital" }, [
                          _vm._v(_vm._s(_vm.sessionModPreference)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        _vm.selected
          ? {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("div", { staticClass: "mt-2" }, [
                        _c("dl", [
                          _c("dt", [_vm._v("Session Comments:")]),
                          _vm._v(" "),
                          _c("dd", { staticClass: "ml-2 keep-format" }, [
                            _vm._v(_vm._s(_vm.sessionComments)),
                          ]),
                          _vm._v(" "),
                          _c("dt", [_vm._v("Bio:")]),
                          _vm._v(" "),
                          _c("dd", { staticClass: "ml-2" }, [
                            _vm.selected.bio
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.selected.bio),
                                  },
                                })
                              : _c(
                                  "span",
                                  { staticClass: "text-muted font-italic" },
                                  [_vm._v("Not Specified")]
                                ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex flex-row mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "w-50 mr-2" },
                          [
                            _c("h5", [_vm._v("Demographics")]),
                            _vm._v(" "),
                            _c("dl-person", {
                              attrs: {
                                fields: [
                                  "ethnicity",
                                  "gender",
                                  "age_at_convention",
                                  "romantic_sexual_orientation",
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "w-50" },
                          [
                            _c("h5", [_vm._v("Community Memberships")]),
                            _vm._v(" "),
                            _c("dl-person", {
                              attrs: {
                                fields: [
                                  "othered",
                                  "indigenous",
                                  "black_diaspora",
                                  "non_us_centric_perspectives",
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Other Information")]),
                      _vm._v(" "),
                      _c("dl-person", {
                        attrs: {
                          fields: [
                            "do_not_assign_with",
                            "can_stream",
                            "can_stream_exceptions",
                            "can_record",
                            "can_record_exceptions",
                            "is_local",
                            "moderation_experience",
                            "languages_fluent_in",
                          ],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "can_stream-val",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm.selected.can_stream
                                      )
                                    )
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "can_record-val",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm.selected.can_record
                                      )
                                    )
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "can_stream_exceptions-label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "Topics participant does not want to be streamed while talking about"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "can_record_exceptions-label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "Topics participant does not want to be recorded while talking about"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2320209065
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }