var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mb-1", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        {
          staticClass: "p-1 schedule-title",
          attrs: { "header-tag": "header", role: "tab" },
        },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: _vm.id,
                  expression: "id",
                },
              ],
              staticClass: "text-left",
              attrs: { block: "", variant: "none", squared: "" },
            },
            [
              _vm._t("title", function () {
                return [_vm._v(_vm._s(_vm.title))]
              }),
              _vm._v(" "),
              _vm.dirty
                ? _c(
                    "span",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      staticClass: "pl-2",
                      attrs: { title: "Unsaved changes" },
                    },
                    [
                      _c("b-icon-exclamation-circle-fill", {
                        attrs: { variant: "warning" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "float-right when-closed" },
                [_c("b-icon-plus", { attrs: { scale: "2" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "float-right when-open" },
                [_c("b-icon-dash", { attrs: { scale: "2" } })],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: _vm.id, role: "tabpanel", value: _vm.value },
          on: {
            show: _vm.show,
            input: function ($event) {
              return _vm.$emit("input", $event)
            },
          },
        },
        [_c("b-card-body", [_vm._t("default")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }