var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.survey
    ? _c("div", [
        _vm.is_assigned
          ? _c("div", { staticClass: "scrollable" }, [
              _vm.selectedPage
                ? _c(
                    "div",
                    { staticClass: "survey-page" },
                    [
                      _c(
                        "b-alert",
                        {
                          staticClass:
                            "mt-3 alert-bright d-flex align-items-center",
                          attrs: {
                            show: _vm.redirMessage,
                            dismissible: "",
                            fade: "",
                            variant: "warning",
                          },
                          on: {
                            dismissed: function ($event) {
                              return _vm.redirShown()
                            },
                          },
                        },
                        [
                          _c("b-icon-exclamation-triangle", {
                            staticClass: "h3 mb-0 mr-3",
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.SURVEY_REDIRECT) +
                              "\n        "
                          ),
                          _c("b-icon-exclamation-triangle", {
                            staticClass: "h3 mb-0 ml-3",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.selectedSurveyFirstPage
                        ? _c("h1", [
                            _vm._v(_vm._s(_vm.selectedSurveyFirstPage.title)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-alert",
                        { attrs: { show: "", variant: "info" } },
                        [
                          _vm._v(_vm._s(_vm.SURVEY_LINKED_FIELD1)),
                          _c("linked-field-icon", {
                            attrs: { linked_field: true },
                          }),
                          _vm._v(_vm._s(_vm.SURVEY_LINKED_FIELD2)),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.firstPage
                        ? _c("h2", [_vm._v(_vm._s(_vm.selectedPage.title))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var handleSubmit = ref.handleSubmit
                                return [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return handleSubmit(_vm.submit)
                                        },
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.selectedPageQuestions,
                                        function (q) {
                                          return _c("survey-question", {
                                            key: q.id,
                                            attrs: {
                                              question: q,
                                              answerable: "",
                                            },
                                            on: { nextPage: _vm.setNextPageId },
                                          })
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm.submitted
                                        ? _c(
                                            "p",
                                            { staticClass: "float-right mt-2" },
                                            [
                                              _vm._v(
                                                "You submitted the survey! YAY!"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end mt-2",
                                        },
                                        [
                                          !_vm.firstPage
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { variant: "link" },
                                                  on: { click: _vm.prev },
                                                },
                                                [_vm._v("Previous Page")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip",
                                                  value: {
                                                    disabled:
                                                      !_vm.submit_disabled,
                                                  },
                                                  expression:
                                                    "{disabled: !submit_disabled}",
                                                },
                                              ],
                                              attrs: {
                                                title:
                                                  _vm.submit_disabled_tooltip,
                                              },
                                            },
                                            [
                                              (_vm.nextPageId === -1 ||
                                                (!_vm.nextPageId &&
                                                  _vm.lastPage)) &&
                                              !_vm.submitted
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "primary",
                                                        type: "submit",
                                                        disabled:
                                                          _vm.submit_disabled,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.survey
                                                            .submit_string ||
                                                            "Submit"
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.nextPageId !== -1 && !_vm.lastPage
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "primary",
                                                    type: "submit",
                                                    tabindex: "1",
                                                  },
                                                },
                                                [_vm._v("Next Page")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2144666986
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _c("div", [_c("h1", [_vm._v(_vm._s(_vm.SURVEY_NOT_ASSIGNED))])]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }