var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.help-modal",
              modifiers: { "help-modal": true },
            },
          ],
          staticClass: "text-light",
          attrs: { variant: "link" },
        },
        [_vm._v("Help")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "help-modal",
            title: "Help",
            "modal-class": "help-modal",
            "ok-only": "",
          },
        },
        [
          _c("p", [
            _vm._v("If you need help, you can email "),
            _c("br"),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.mailto, target: "_blank" } }, [
              _vm._v(_vm._s(_vm.email)),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }