var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bulk-edit-modal", {
        attrs: { id: "bulk-edit-status", title: "Bulk Edit Status(es)" },
        on: { ok: _vm.onSaveMassEdit },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("b-form-select", {
                  attrs: { options: _vm.sessionStatusOptionsNoDropped },
                  model: {
                    value: _vm.selectedSessionState,
                    callback: function ($$v) {
                      _vm.selectedSessionState = $$v
                    },
                    expression: "selectedSessionState",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "confirm-default",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "\n        Please confirm that you want to change the\n        status of " +
                      _vm._s(_vm.editableIds.length) +
                      " " +
                      _vm._s(
                        _vm.editableIds.length == 1 ? "session" : "sessions"
                      ) +
                      " to '" +
                      _vm._s(_vm.SESSION_STATUS[_vm.selectedSessionState]) +
                      "'\n      "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("table-vue", {
        ref: "sessions-table",
        attrs: {
          defaultSortBy: "sessions.title",
          model: _vm.model,
          columns: _vm.columns,
          stateName: "session-table-search-state",
          selectMode: "multi",
        },
        on: { new: _vm.openNewModal },
        scopedSlots: _vm._u([
          {
            key: "left-controls",
            fn: function (ref) {
              var editableIds = ref.editableIds
              return [
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "primary",
                          disabled: editableIds.length == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onEditStates(editableIds)
                          },
                        },
                      },
                      [_vm._v("Edit Status(es)\n        ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(title)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.title
                  ? _c("tooltip-overflow", { attrs: { title: item.title } }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(item.title) },
                      }),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(item_notes)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.item_notes
                  ? _c(
                      "tooltip-overflow",
                      { attrs: { title: item.item_notes } },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(item.item_notes) },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(description)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.description
                  ? _c(
                      "tooltip-overflow",
                      { attrs: { title: item.description } },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(item.description) },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(area_list)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.area_list
                  ? _c(
                      "tooltip-overflow",
                      { attrs: { title: _vm.formatAreas(item.area_list) } },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatAreas(item.area_list))),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(start_time)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.start_time
                  ? _c(
                      "span",
                      [
                        _c(
                          "tooltip-overflow",
                          {
                            attrs: {
                              title: _vm.formatLocaleDate(item.start_time),
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.formatLocaleDate(item.start_time)) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(created_at)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.created_at
                  ? _c(
                      "span",
                      [
                        _c(
                          "tooltip-overflow",
                          {
                            attrs: {
                              title: _vm.formatLocaleDate(item.created_at),
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.formatLocaleDate(item.created_at)) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(updated_at)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.updated_at
                  ? _c(
                      "span",
                      [
                        _c(
                          "tooltip-overflow",
                          {
                            attrs: {
                              title: _vm.formatLocaleDate(item.updated_at),
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.formatLocaleDate(item.updated_at)) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(publish)",
            fn: function () {
              return [
                _c("div", { staticClass: "text-center text-muted" }, [
                  _vm._v("—"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: { id: "add-session", title: "Add Session" },
          on: { ok: _vm.onNew },
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Session Title" } },
            [
              _c("b-form-input", {
                attrs: { id: "session-new-session-title", type: "text" },
                model: {
                  value: _vm.newSessionTitle,
                  callback: function ($$v) {
                    _vm.newSessionTitle = $$v
                  },
                  expression: "newSessionTitle",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }