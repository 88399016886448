var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey scrollable" },
    [
      _c("b-button", { attrs: { variant: "link" }, on: { click: _vm.back } }, [
        _vm._v("Back"),
      ]),
      _vm._v(" "),
      _vm.survey
        ? _c(
            "b-form-group",
            {
              staticClass: "mx-3",
              attrs: {
                id: "survey-name-group",
                label: "Survey Name",
                "label-for": "survey-name",
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "survey-name", type: "text" },
                on: {
                  blur: function ($event) {
                    return _vm.saveSurvey()
                  },
                },
                model: {
                  value: _vm.survey.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "name", $$v)
                  },
                  expression: "survey.name",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.survey
        ? _c(
            "b-form-group",
            {
              staticClass: "mx-3",
              attrs: {
                id: "survey-description-group",
                label: "Survey Description",
                "label-for": "survey-description",
              },
            },
            [
              _c("b-form-textarea", {
                attrs: { id: "survey-description" },
                on: {
                  blur: function ($event) {
                    return _vm.saveSurvey()
                  },
                },
                model: {
                  value: _vm.survey.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "description", $$v)
                  },
                  expression: "survey.description",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          staticClass: "alert-bright mx-3",
          attrs: { show: _vm.survey.public, variant: "warning" },
        },
        [_vm._v(_vm._s(_vm.SURVEY_PUBLIC_NO_EDIT))]
      ),
      _vm._v(" "),
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            {
              attrs: {
                "button-id": "questionTab",
                title: "Question",
                active: !_vm.responses && !_vm.survey.public,
                lazy: "",
                disabled: _vm.survey.public,
              },
            },
            [_c("edit-survey", { attrs: { "survey-id": _vm.id } })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              attrs: { title: "Responses", active: !!_vm.responses, lazy: "" },
            },
            [_c("view-responses", { attrs: { "survey-id": _vm.id } })],
            1
          ),
          _vm._v(" "),
          _c("survey-settings-tab", { attrs: { lazy: "" } }),
          _vm._v(" "),
          _c("b-tab", {
            attrs: { title: "Audit Log", disabled: "", lazy: "" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-tooltip", {
        attrs: { title: _vm.questionsTitle, target: "questionTab" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }