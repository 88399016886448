var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid scrollable" },
    [
      _c("h1", [_vm._v("Preview features")]),
      _vm._v(" "),
      _c("h2", [_vm._v("Page content editor (for the dashboard)")]),
      _vm._v(" "),
      _c("page-content-editor"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }