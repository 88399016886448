var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentSettings
    ? _c(
        "div",
        [
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.EVENT_SETTINGS_MUST_RELOAD))]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.parameters, function (parameter) {
            return _c(
              "div",
              { key: parameter.paremeter_name },
              [
                parameter
                  ? _c("config-editor", {
                      attrs: { model: "configuration", parameter: parameter },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }