var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-3 scrollable" }, [
    _c("p", { staticClass: "mt-2" }, [_vm._v("These are interim reports.")]),
    _vm._v(" "),
    _c("ul", [
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "#participants" } }, [
            _vm._v("Participants"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "#sessions" } }, [
            _vm._v("Sessions"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "#conflicts" } }, [
            _vm._v("Conflicts"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "#schedule" } }, [
            _vm._v("Schedule"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "#prog-ops" } }, [
            _vm._v("Program Ops"),
          ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("ul", [
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("li", [
        _vm.currentUserIsStaff
          ? _c(
              "span",
              {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                staticClass: "text-muted font-italic",
                attrs: {
                  title:
                    "You do not have the right set of permissions to run this report.",
                },
              },
              [_vm._v("Participants and Do Not Assign With")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentUserIsAdmin
          ? _c(
              "a",
              {
                attrs: {
                  href: "/report/participant_do_not_assign_with",
                  target: "_blank",
                },
              },
              [_vm._v("Participants and Do Not Assign With")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(5),
      ]),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _vm._m(8),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _vm._m(10),
      _vm._v(" "),
      _vm._m(11),
      _vm._v(" "),
      _vm._m(12),
      _vm._v(" "),
      _vm._m(13),
    ]),
    _vm._v(" "),
    _vm._m(14),
    _vm._v(" "),
    _vm._m(15),
    _vm._v(" "),
    _vm._m(16),
    _vm._v(" "),
    _vm._m(17),
    _vm._v(" "),
    _vm._m(18),
    _vm._v(" "),
    _vm._m(19),
    _vm._v(" "),
    _vm._m(20),
    _vm._v(" "),
    _vm._m(21),
    _vm._v(" "),
    _c("div", { staticStyle: { clear: "both", height: "5rem" } }, [
      _vm._v(" "),
    ]),
    _vm._v(" "),
    _vm.currentUserIsStaff
      ? _c(
          "span",
          {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "text-muted font-italic",
            attrs: {
              title:
                "You do not have the right set of permissions to run this report.",
            },
          },
          [_vm._v("User Privileges")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentUserIsAdmin
      ? _c(
          "a",
          {
            attrs: {
              href: "/report/program_ops_reports/user_privileges",
              target: "_blank",
            },
          },
          [_vm._v("User Privileges")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm._m(22),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("a", { attrs: { id: "participants" } }),
      _vm._v("Participants"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "/report/participant_selections", target: "_blank" } },
        [_vm._v("Participant Selections")]
      ),
      _c("br"),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": Sessions and rankings selected by people, one line per person and session"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, participant status, session title, ranking, ranking notes, area(s) for the session, has person been assigned (y/n), has session been scheduled (y/n)"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": any session selected by at least one person"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": participant status of applied, probable, vetted, invite_pending, invited, accepted\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/participant_availabilities",
            target: "_blank",
          },
        },
        [_vm._v("Participant Availabilities")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ': Information in the person\'s "Availability & Interests" tab (daily limit and con limit, days/times available, special events to attend)'
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ': Person name, published name, participant status, attendance type (in-person, virtual, hybrid), availabilities (days/times), limits (daily & con/"global"), exclusions (special events to attend), availability notes'
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": participant status of applied, probable, vetted, invite_pending, invited, accepted\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/assigned_sessions_by_participant",
            target: "_blank",
          },
        },
        [_vm._v("Assigned Sessions by Participant")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": People assigned to sessions, one line per person and session"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, participant status, session title, person’s role in the session, has session been scheduled (y/n)"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(
          ": any session with at least one person assigned as moderator, participant, invisible participant, or reserved"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": session role of moderator, participant, invisible participant, or reserved\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "/report/people_and_submissions", target: "_blank" } },
        [_vm._v("People and Survey Submissions")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": List of surveys taken, including day/time submitted, one line per person"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, primary email, attendance type, participant status, surveys taken"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": participant status of applied, probable, vetted, invite_pending, invited, accepted\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ml-2" }, [
      _c("strong", [_c("em", [_vm._v("Description")])]),
      _vm._v(
        ": People who should not be assigned to the same session, one line per person and session; available only to users with permission to view sensitive data"
      ),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_c("em", [_vm._v("Fields")])]),
      _vm._v(
        ": Person name, published name, session title, area(s) of session, names of other people assigned to the session, names of people not to assign to the same session"
      ),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_c("em", [_vm._v("Session data included")])]),
      _vm._v(": all scheduled sessions"),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_c("em", [_vm._v("Person data included")])]),
      _vm._v(
        ": moderators, participants, invisible participants who listed information about who not to assign with\n      "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/session_reports/participants_over_session_limits",
            target: "_blank",
          },
        },
        [_vm._v("Participants over Daily Limits")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": People scheduled for more sessions than they specified in their daily and/or con limit, one line per person and daily/con limit exceeded"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ': Person name, published name, participant status, day (including individual days and "All" for con limit), session count (number of assigned sessions), person’s limit (as specified in "Availabilities & Interests")'
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": all scheduled sessions"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": moderators, participants exceeding specified daily or con limit\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/session_reports/participants_over_con_session_limits",
            target: "_blank",
          },
        },
        [_vm._v("Participants over Con Limit")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": People scheduled for more sessions than the con allows, with some session format exceptions, one line per person"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, participant status, session count (number of assigned sessions), con limit (maximum number of sessions allowed by con of 6)"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(
          ": all scheduled sessions except those with format of autographing, meetings, readings, rehearsals, and table talks"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": moderators, participants exceeding con limit of 6 sessions, not including the format exceptions\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/session_reports/non_accepted_on_schedule",
            target: "_blank",
          },
        },
        [_vm._v("Non-Accepted Participants on Scheduled Sessions")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ': People with participant status other than "accepted" or "not_set" assigned to scheduled sessions, one line per person and session'
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, participant status, session title, area(s) of session"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": all scheduled sessions"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ': moderators, participants, invisible participants with a participant status that is not "accepted" or "not_set"\n      '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/session_reports/invited_accepted_not_scheduled",
            target: "_blank",
          },
        },
        [_vm._v("Invited and Accepted not on Schedule")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": People with a participant status of accepted, invited, or invite_pending who are not assigned to any sessions, one line per person"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, participant status, attendance type (in-person, virtual, hybrid), person’s bio"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": all scheduled sessions"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": people with a participant status of accepted, invited, or invite_pending who are assigned to no sessions, or who are assigned as invisible participants or reserved on one or more sessions\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/people_reports/record_stream_permissions",
            target: "_blank",
          },
        },
        [_vm._v("Participant Recording and Streaming Permissions")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": List of participants with their recording and streaming permissions and exclusions, one line per person"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, primary email, attendance type, participant status, permission to stream, exclusions for streaming, permission to record, exclusions to recording, schedule"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": all scheduled sessions"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(": moderators, participants\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/people_reports/moderators",
            target: "_blank",
          },
        },
        [_vm._v("Participants Moderating at least One Session")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": People serving as moderator for at least one session, and the session information, one line per person and session"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Person name, published name, primary email, attendance type (in-person, virtual, hybrid), participant status, session title, session environment"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": all scheduled sessions"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(": moderators\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/people_reports/social_media",
            target: "_blank",
          },
        },
        [_vm._v("Participants with Social Media")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": Social media information for participants, one line per person"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Published name, attendance type, participant status, participant bio, participant social media block"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": all scheduled sessions"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ": moderators and participants assigned to at least one scheduled session\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/report/people_reports/mis_matched_envs",
            target: "_blank",
          },
        },
        [
          _vm._v(
            "Participants with Mismatching Session Environments and Attendance Types"
          ),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ml-2" }, [
        _c("strong", [_c("em", [_vm._v("Description")])]),
        _vm._v(
          ": Participants whose attendance type does not match the session environment for sessions they are assigned to, one line per participant and session"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Fields")])]),
        _vm._v(
          ": Published name, primary email, attendance type, participant status, session title, session room, session start time, session environment"
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Session data included")])]),
        _vm._v(": all scheduled sessions"),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_c("em", [_vm._v("Person data included")])]),
        _vm._v(
          ': moderators and participants with attendance type of "virtual" assigned to session with environment of "hybrid" or "in person", or with attendance type of "in person" assigned to session with environment of "virtual"\n      '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("a", { attrs: { id: "sessions" } }),
      _vm._v("Sessions"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c(
          "a",
          { attrs: { href: "/report/session_selections", target: "_blank" } },
          [_vm._v("Session Selections")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Sessions and rankings selected by people, one line per session and person"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, person name, person published name, participant status, ranking, ranking notes, area(s) of session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all sessions that at least one person selected"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(
            ": participant status of applied, probable, vetted, invite_pending, invited, accepted\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/sessions_with_participants",
              target: "_blank",
            },
          },
          [_vm._v("Sessions with Participants")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Sessions with people already assigned, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, session format, area(s) of session, moderators, participants, reserves, has session been scheduled (y/n)"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all sessions with at least one person assigned"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, reserved\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/session_with_no_moderator",
              target: "_blank",
            },
          },
          [_vm._v("Sessions with no Moderator")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Scheduled sessions with no moderator assigned, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session start time, session format, people assigned (participants, invisible participants), reserved people"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": participants, invisible participants, reserved\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/scheduled_session_no_people",
              target: "_blank",
            },
          },
          [_vm._v("Scheduled Sessions with no Participants")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Scheduled sessions with no assigned moderators or participants, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session start time, room"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/assigned_sessions_not_scheduled",
              target: "_blank",
            },
          },
          [_vm._v("Sessions with Participants not Scheduled")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Sessions that are not scheduled but have assigned people, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, moderators, participants, invisible participants"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(
            ": all unscheduled sessions with at least one person assigned as moderator, participant, and/or invisible participant"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/panels_with_too_few_people",
              target: "_blank",
            },
          },
          [_vm._v("Panels with too few Participants")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Scheduled sessions (panel format only) with 3 or fewer assigned people (moderators, participants), one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session start time, participant count, participant count lower bound (3), assigned participants, moderators"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/panels_with_too_many_people",
              target: "_blank",
            },
          },
          [_vm._v("Panels with too many Participants")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Scheduled sessions (panel format only) with 6 or more assigned people (moderators, participants), one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session start time, participant count, participant count upper bound (6), assigned participants, moderators"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/session_copy_edit_status",
              target: "_blank",
            },
          },
          [_vm._v("Session Copy-Edit Status")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": The copy-edited status of each session, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session status, has session been copy edited (y/n), has session been scheduled (y/n), visibility (visible, not visible)"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all sessions\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/streamed_and_recorded",
              target: "_blank",
            },
          },
          [_vm._v("Scheduled Sessions Streamed and/or Recorded")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Information about livestreaming and recording, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, session start time, session room, session format, session environment, is session livestreamed (y/n), is session recorded (y/n)"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions\n      "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("a", { attrs: { id: "conflicts" } }),
      _vm._v("Conflicts"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/conflict_reports/people_outside_availability",
              target: "_blank",
            },
          },
          [_vm._v("People outside of Availability")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": People scheduled on days/times that they did not mark as available, one line per person and session, except where conflict has been marked ignored"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Person name, published name, participant status, session title, area(s) of session, session start time, session duration, participant availability (days/times)"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/conflict_reports/people_double_booked",
              target: "_blank",
            },
          },
          [_vm._v("People double booked")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": People assigned to more than one scheduled session at the same time, one line per person and double booking occurrence, except where conflict has been marked ignored"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Person name, published name, participant status, time, session title, area(s) of session, room, conflict session title, conflict session area, conflict room"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/conflict_reports/person_exclusion_conflicts",
              target: "_blank",
            },
          },
          [_vm._v("Person scheduled against a conflict item")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": People assigned to a session that occurs at the same time as a special event they want to attend, one line per conflict occurrence, except where conflict has been marked ignored"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Person name, published name, participant status, session title, area(s) of session, session start time, session duration, conflict session (special event), conflict session (special event) start time"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/conflict_reports/back_to_back",
              target: "_blank",
            },
          },
          [_vm._v("Back to Back")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": People assigned to two sessions in a row (where the second session begins less than 40 minutes after the first ends), one line per session pair, except where conflict has been marked ignored"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Person name, published name, participant status, session 1 title, area(s) of session, session start time, session duration, room, session 2 title, area(s) of session, session start time, session duration, room"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/conflict_reports/back_to_back_to_back",
              target: "_blank",
            },
          },
          [_vm._v("Back to Back to Back")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": People assigned to three sessions in a row (where one session begins less than 40 minutes after the previous one), one line per set of 3 sessions"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Person name, published name, participant status, session 1 title, area(s) of session, session start time, session duration, room, session 2 title, area(s) of session, session start time, session duration, room, session 3 title, area(s) of session, session start time, session duration, room"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "report/conflict_reports/multiple_sessions_in_room",
              target: "_blank",
            },
          },
          [_vm._v("Multiple Sessions in a Room")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Two or more sessions scheduled for the same room at the same day/time, one line per occurrence, except where conflict has been marked ignored"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(": Room, session title, conflict session title, start time"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/conflict_reports/all_ignored_conflicts",
              target: "_blank",
            },
          },
          [_vm._v("Ignored Person Conflicts")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Ignored conflicts in these categories: people outside of availability, people double booked, person scheduled against a conflict item, back to back), one line per ignored conflict"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session start time, session duration, room, person name, conflict type"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/conflict_reports/all_conflicts",
              target: "_blank",
            },
          },
          [_vm._v("All Conflicts")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": All conflicts in these categories: people outside of availability, people double booked, person scheduled against a conflict item, back to back), one line per conflict"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session start time, session duration, room, person name, conflict type"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("a", { attrs: { id: "schedule" } }),
      _vm._v("Schedule"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/schedule_by_room_then_time",
              target: "_blank",
            },
          },
          [_vm._v("Schedule by Room then Time")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(": All scheduled sessions, one line per room and day/time"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session start time, session duration, room, assigned participants, moderators"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "/report/schedule_by_person", target: "_blank" } },
          [_vm._v("Schedule by Participant")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Schedule for each participant, one line per person and session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Person name, published name, participant status, session title, area(s) of session, session start time, room, moderator (y/n), invisible participant (y/n)"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants, invisible participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "/report/schedule_accpetance", target: "_blank" } },
          [_vm._v("Participants with Approved Draft and Firm Schedules")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": People with a participant status that is not rejected, declined, or not_set, with indication whether and when they have approved their draft and firm schedules, with comments if not approved, one line per person"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Person name, published name, primary email, attendance type (in-person, virtual, hybrid), participant status, draft approval, draft comments, draft edited time, firm approval, firm comments, firm edited time"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(
            ": people with a participant status that is not rejected, declined, or not_set, including those who are not assigned to any sessions\n      "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("a", { attrs: { id: "prog-ops" } }),
      _vm._v("Program Ops"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/daily_grid",
              target: "_blank",
            },
          },
          [_vm._v("Daily Grid")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(": Session information for daily grid, one line per session"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, area(s) of session, session format, session start time, session duration, session room"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all published sessions in the most recent publish\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/program_ops_reports/back_of_badge",
              target: "_blank",
            },
          },
          [_vm._v("Back of Badge")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Participant schedule for mail merge, one line per person, with variable line length based on number of assigned sessions"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Published name, Session 1 Title, Session 1 Truncated Title (30 characters max), Session 1 Start Time, Session 1 Duration, Session 1 Room, Session 1 Format, Session 1 Participant Role, then repeat the 7 session columns as needed"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(
            ': all scheduled sessions with status of "reviewed" or "revised"'
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(
            ': moderators, participants, invisible participants with attendance type of "in person" or "hybrid"\n      '
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/program_ops_reports/sign_up_sessions",
              target: "_blank",
            },
          },
          [_vm._v("Sessions Requiring Attendee Signups")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Maximum number of attendees in sessions requiring signup, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, session description, session room, session start time, session duration, session format, participants, session environment, max number of attendees"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all published sessions in the most recent publish"),
          _c("br"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/session_reports/session_needs",
              target: "_blank",
            },
          },
          [_vm._v("Session Needs")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(": Session setup requirements, one line per session"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, session start time, session room, session environment, session format, session area(s), required room features∕services, tech∕hotel notes, room setup"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all published sessions in the most recent publish"),
          _c("br"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/program_ops_reports/session_minors",
              target: "_blank",
            },
          },
          [_vm._v("Sessions and Minors")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Minors participation and attendee age restrictions, one line per session"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, session description, session start time, session room, attendee age restriction, minors participation"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all scheduled sessions"),
          _c("br"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/program_ops_reports/room_signs",
              target: "_blank",
            },
          },
          [_vm._v("Room Sign Data for Mail Merge")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Room sign data for mail merge, one line per room and day of week"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Room, day of week, sessions (title, start time, description, moderators, participants)"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all published sessions in the most recent publish"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/program_ops_reports/table_tents",
              target: "_blank",
            },
          },
          [_vm._v("Table Tent Data for Mail Merge")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Table tent data for mail merge, one line per session and participant"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Session title, published name, session description, scheduled participant notes, moderators and their pronouns, participants and their pronouns"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(
            ': published sessions  in the most recent publish, with environment of "in person" or "hybrid"'
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators, participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/program_ops_reports/virtual_people",
              target: "_blank",
            },
          },
          [_vm._v("Participant Emails and Virtual Sessions")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": List of emails for moderators and participants of published sessions, with indicator about whether they are assigned to any session(s) of environment virtual, one line per participant"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Published name, name, whether person is assigned to a virtual session (y ∕ n), primary email, other emails"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(": all published sessions in the most recent publish"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(": moderators and participants\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/integrations_reports/airmeet_magic_link",
              target: "_blank",
            },
          },
          [_vm._v("Airmeet Magic Links")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Links to get each participant (speaker) into Airmeet, one line per person. For emergency customer service use."
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(": Published name, primary email, Airmeet magic link"),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(
            ": all moderators and participants assigned to virtual sessions\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "/report/integrations_reports/airmeet_diffs",
              target: "_blank",
            },
          },
          [_vm._v("Airmeet Diffs")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ml-2" }, [
          _c("strong", [_c("em", [_vm._v("Description")])]),
          _vm._v(
            ": Changes to person information since the last sync (manual or automatic) with Airmeet, one line per change"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Fields")])]),
          _vm._v(
            ": Time of last sync, time when person last updated, Airmeet email, what changed, from what, to what"
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Session data included")])]),
          _vm._v(
            ': all published sessions with environment of "virtual", in the most recent publish'
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_c("em", [_vm._v("Person data included")])]),
          _vm._v(
            ": moderators and participants assigned to published virtual sessions, whose published name, email, or bio has changed since their last sync to Airmeet\n      "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ml-2" }, [
      _c("strong", [_c("em", [_vm._v("Description")])]),
      _vm._v(": Login (primary email) listing, one line per person"),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_c("em", [_vm._v("Fields")])]),
      _vm._v(
        ": Primary email, published name, list of roles, recently logged in at"
      ),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_c("em", [_vm._v("Person data included")])]),
      _vm._v(": all people with a role or staff and∕or admin\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }