var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "span",
        {
          staticClass: "d-inline-block",
          attrs: { id: _vm.spanId, tabindex: _vm.disabled ? 0 : -1 },
        },
        [
          _c(
            "b-button",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.bottom",
                      modifiers: { bottom: true },
                    },
                  ],
                  class: [
                    "mx-1",
                    {
                      "px-0": _vm.variant === "link",
                      "px-2": _vm.variant === "primary",
                    },
                  ],
                  style: _vm.style,
                  attrs: {
                    size: _vm.size,
                    variant: _vm.variant,
                    id: _vm.id,
                    disabled: _vm.disabled,
                  },
                },
                "b-button",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              _vm._t(
                "default",
                function () {
                  return [
                    _vm.icon
                      ? _c("b-icon", {
                          attrs: { icon: _vm.icon, variant: _vm.iconVariant },
                        })
                      : _vm._e(),
                  ]
                },
                null,
                { variant: _vm.iconVariant }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.disabled
        ? _c(
            "b-tooltip",
            { attrs: { target: _vm.spanId, placement: "bottom" } },
            [_vm._v(_vm._s(_vm.disabledTooltip))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }