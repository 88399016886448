var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-row w-100" },
    [
      _c(
        "validation-observer",
        { ref: "emailObserver", attrs: { slim: "" } },
        [
          _c("ValidationProvider", {
            staticClass: "w-75 mt-2 mr-3",
            attrs: {
              mode: "aggressive",
              rules: "email",
              name: "Email",
              skipIfEmpty: true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var valid = ref.valid
                  var errors = ref.errors
                  return [
                    _c("b-form-input", {
                      attrs: {
                        disabled: _vm.disabled,
                        state: _vm.calcValid(errors, valid),
                      },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.value.email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.value,
                            "email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "value.email",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-form-invalid-feedback", [_vm._v(_vm._s(errors[0]))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-25" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row w-100" },
          [
            _c("b-form-radio", {
              staticClass: "mt-2 pt-1",
              attrs: {
                switch: "",
                size: "lg",
                value: "true",
                checked: _vm.isdefault,
                disabled: _vm.disabled,
                name: _vm.radioGroup,
              },
              on: { change: _vm.onCheck },
            }),
            _vm._v(" "),
            _vm.canDelete
              ? _c(
                  "b-button",
                  {
                    staticClass: "mt-2",
                    attrs: { size: "sm", title: "Delete", variant: "primary" },
                    on: { click: _vm.onDelete },
                  },
                  [_c("b-icon-trash")],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }