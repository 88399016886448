var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selected
    ? _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column w-50 p-2" },
            [
              _c(
                "div",
                [
                  _c("h5", [_vm._v("Identity")]),
                  _vm._v(" "),
                  _c("dl-person", { attrs: { fields: ["name", "pseudonym"] } }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.readOnly
                ? _c("div", [
                    _c("h5", [_vm._v("Emails")]),
                    _vm._v(" "),
                    _c(
                      "dl",
                      [
                        _c("dt", [_vm._v("Primary email (login)")]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "font-italic ml-2" }, [
                          _vm._v(_vm._s(_vm.selected.primary_email.email)),
                        ]),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Additional emails")]),
                        _vm._v(" "),
                        _vm._l(_vm.additionalEmails, function (email) {
                          return _c(
                            "dd",
                            { key: email.id, staticClass: "ml-2 font-italic" },
                            [_vm._v(_vm._s(email.email))]
                          )
                        }),
                        _vm._v(" "),
                        !_vm.additionalEmails.length
                          ? _c(
                              "dd",
                              { staticClass: "ml-2 font-italic text-muted" },
                              [_vm._v("None")]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.readOnly
                ? _c("email-addresses-editor", {
                    staticClass: "mb-4",
                    attrs: { model: "email_address" },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("input", _vm.selected)
                      },
                    },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h5",
                [
                  _vm._v("Additional Information "),
                  !_vm.readOnly && _vm.showAdditionalInfo
                    ? _c("edit-button", {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.person-misc-modal",
                            modifiers: { "person-misc-modal": true },
                          },
                        ],
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showAdditionalInfo
                ? _c("dl-person", {
                    attrs: { fields: _vm.miscFields },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "can_stream-val",
                          fn: function () {
                            return [_vm._v(_vm._s(_vm.can_stream_label))]
                          },
                          proxy: true,
                        },
                        {
                          key: "can_record-val",
                          fn: function () {
                            return [_vm._v(_vm._s(_vm.can_record_label))]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2169034185
                    ),
                  })
                : _c("div", [_vm._v("Comming Soon")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column w-50 p-2" },
            [
              _vm.eventVirtual && _vm.readOnly
                ? _c(
                    "div",
                    [
                      _c("h5", [_vm._v("Virtual")]),
                      _vm._v(" "),
                      _c("dl-person", {
                        attrs: { fields: ["attendance_type", "timezone"] },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "attendance_type-val",
                              fn: function (ref) {
                                var value = ref.value
                                return [
                                  _vm._v(
                                    _vm._s(_vm.PERSON_ATTENDANCE_TYPE[value])
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1696114784
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.eventVirtual && !_vm.readOnly
                ? _c(
                    "div",
                    [
                      _c("h5", [_vm._v("Virtual")]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c("b-form-radio-group", {
                        attrs: {
                          stacked: "",
                          options: _vm.attendanceTypeOptions,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.saveSelected()
                          },
                        },
                        model: {
                          value: _vm.selected.attendance_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.selected, "attendance_type", $$v)
                          },
                          expression: "selected.attendance_type",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              "At the time of the convention I will be at UTC Offset",
                          },
                        },
                        [
                          _c("timezone-selector", {
                            staticClass: "mb-2",
                            attrs: {
                              disabled:
                                _vm.disabled ||
                                _vm.selected.attendance_type != "virtual",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.saveSelected()
                              },
                            },
                            model: {
                              value: _vm.selected.timezone,
                              callback: function ($$v) {
                                _vm.$set(_vm.selected, "timezone", $$v)
                              },
                              expression: "selected.timezone",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n          Your time now in the selected timezone is "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.youTimeNow))]),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "\n          If you are not sure what your UTC offset will be, or want to verify,\n          please go to\n          "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "blank",
                                  href: "https://www.timeanddate.com/worldclock/meeting.html",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            https://www.timeanddate.com/worldclock/meeting.html\n          "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n          and check by specifying the date of September 1 2022 and your location as well as UTC/GMT\n        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h5",
                [
                  _vm._v("Bio "),
                  !_vm.readOnly
                    ? _c("edit-button", {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.person-bio-modal",
                            modifiers: { "person-bio-modal": true },
                          },
                        ],
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "ml-2",
                domProps: { innerHTML: _vm._s(_vm.selected.bio) },
              }),
              _vm._v(" "),
              _c(
                "h5",
                { staticClass: "mt-3" },
                [
                  _vm._v("Social Media "),
                  !_vm.readOnly
                    ? _c("edit-button", {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.person-social-modal",
                            modifiers: { "person-social-modal": true },
                          },
                        ],
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("dl-person", { attrs: { fields: _vm.socialFields } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("person-edit-modal", {
            attrs: {
              id: "person-bio-modal",
              person: _vm.selected,
              data: { bio: null },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-title",
                  fn: function () {
                    return [
                      _vm._v(
                        "Edit Bio - " + _vm._s(_vm.selected.published_name)
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (ref) {
                    var fields = ref.fields
                    return [
                      _c("plano-editor", {
                        attrs: { type: "classic" },
                        model: {
                          value: fields.bio,
                          callback: function ($$v) {
                            _vm.$set(fields, "bio", $$v)
                          },
                          expression: "fields.bio",
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              1735834621
            ),
          }),
          _vm._v(" "),
          _c("person-edit-modal", {
            attrs: {
              id: "person-misc-modal",
              person: _vm.selected,
              data: _vm.miscData,
              validate: true,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-title",
                  fn: function () {
                    return [
                      _vm._v(
                        "Edit Additional Info - " +
                          _vm._s(_vm.selected.published_name)
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (ref) {
                    var fields = ref.fields
                    return [
                      _c(
                        "validation-provider",
                        {
                          attrs: {
                            name: "Anyone that should not be assigned with",
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "Anyone that should not be assigned to be on a panel with participant",
                              },
                            },
                            [
                              _vm.canEditSensitiveInfo
                                ? _c("b-form-textarea", {
                                    model: {
                                      value: fields.do_not_assign_with,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          fields,
                                          "do_not_assign_with",
                                          $$v
                                        )
                                      },
                                      expression: "fields.do_not_assign_with",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.canEditSensitiveInfo
                                ? _c("b-form-textarea", {
                                    attrs: {
                                      disabled: "",
                                      value: "Restricted",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        { attrs: { name: "Sharing preferences" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "Permission to share email with other Participants",
                              },
                            },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  attrs: { stacked: "" },
                                  model: {
                                    value: fields.can_share,
                                    callback: function ($$v) {
                                      _vm.$set(fields, "can_share", $$v)
                                    },
                                    expression: "fields.can_share",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: true } },
                                    [_vm._v(_vm._s(_vm.yesLabel.label))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: false } },
                                    [_vm._v(_vm._s(_vm.noLabel.label))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              "Permission to be included in a livestreamed program",
                          },
                        },
                        [
                          _c(
                            "validation-provider",
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  attrs: { stacked: "" },
                                  model: {
                                    value: fields.can_stream,
                                    callback: function ($$v) {
                                      _vm.$set(fields, "can_stream", $$v)
                                    },
                                    expression: "fields.can_stream",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: _vm.yesLabel.value } },
                                    [_vm._v(_vm._s(_vm.yesLabel.label))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: _vm.noLabel.value } },
                                    [_vm._v(_vm._s(_vm.noLabel.label))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: _vm.maybeLabel.value } },
                                    [_vm._v(_vm._s(_vm.maybeLabel.label))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "validation-provider",
                            [
                              _c("b-textarea", {
                                model: {
                                  value: fields.can_stream_exceptions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      fields,
                                      "can_stream_exceptions",
                                      $$v
                                    )
                                  },
                                  expression: "fields.can_stream_exceptions",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              "Permission to be included in a recorded program",
                          },
                        },
                        [
                          _c(
                            "validation-provider",
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  attrs: { stacked: "" },
                                  model: {
                                    value: fields.can_record,
                                    callback: function ($$v) {
                                      _vm.$set(fields, "can_record", $$v)
                                    },
                                    expression: "fields.can_record",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: _vm.yesLabel.value } },
                                    [_vm._v(_vm._s(_vm.yesLabel.label))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: _vm.noLabel.value } },
                                    [_vm._v(_vm._s(_vm.noLabel.label))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: _vm.maybeLabel.value } },
                                    [_vm._v(_vm._s(_vm.maybeLabel.label))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "validation-provider",
                            [
                              _c("b-textarea", {
                                model: {
                                  value: fields.can_record_exceptions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      fields,
                                      "can_record_exceptions",
                                      $$v
                                    )
                                  },
                                  expression: "fields.can_record_exceptions",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { switch: "" },
                                  model: {
                                    value: fields.is_local,
                                    callback: function ($$v) {
                                      _vm.$set(fields, "is_local", $$v)
                                    },
                                    expression: "fields.is_local",
                                  },
                                },
                                [_vm._v("Local to the event")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Moderating experience" } },
                            [
                              _c("b-form-textarea", {
                                model: {
                                  value: fields.moderation_experience,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      fields,
                                      "moderation_experience",
                                      $$v
                                    )
                                  },
                                  expression: "fields.moderation_experience",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "Accessibility and/or disability concerns",
                              },
                            },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  attrs: { stacked: "" },
                                  model: {
                                    value: fields.needs_accommodations,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        fields,
                                        "needs_accommodations",
                                        $$v
                                      )
                                    },
                                    expression: "fields.needs_accommodations",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: true } },
                                    [_vm._v(_vm._s(_vm.yesLabel.label))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    { attrs: { value: false } },
                                    [_vm._v(_vm._s(_vm.noLabel.label))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "Accessibility and/or disability details",
                              },
                            },
                            [
                              _c("b-textarea", {
                                model: {
                                  value: fields.accommodations,
                                  callback: function ($$v) {
                                    _vm.$set(fields, "accommodations", $$v)
                                  },
                                  expression: "fields.accommodations",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3585511898
            ),
          }),
          _vm._v(" "),
          _c("person-edit-modal", {
            attrs: {
              id: "person-social-modal",
              person: _vm.selected,
              data: _vm.socialsData,
              validate: true,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-title",
                  fn: function () {
                    return [
                      _vm._v(
                        "Edit Socials - " + _vm._s(_vm.selected.published_name)
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (ref) {
                    var fields = ref.fields
                    return [
                      _c("simple-social", {
                        attrs: {
                          label: "Twitter",
                          rules: {
                            min: 1,
                            max: 15,
                            regex: /^[a-z0-9_]{1,15}$/i,
                          },
                          prepend: "@",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.twitter,
                          callback: function ($$v) {
                            _vm.$set(fields, "twitter", $$v)
                          },
                          expression: "fields.twitter",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: {
                          rules: { min: 5, regex: /^[a-z\d.]{5,}$/i },
                          label: "Facebook",
                          prepend: "facebook.com/",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.facebook,
                          callback: function ($$v) {
                            _vm.$set(fields, "facebook", $$v)
                          },
                          expression: "fields.facebook",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: {
                          rules: {
                            regex:
                              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9\/]+([\-\.]{1}[a-zA-Z0-9\/]+)*\.[a-zA-Z\/]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                          },
                          label: "Website",
                          prepend: "url",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.website,
                          callback: function ($$v) {
                            _vm.$set(fields, "website", $$v)
                          },
                          expression: "fields.website",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: {
                          rules: { regex: /^[a-zA-Z0-9._]+$/ },
                          label: "Instagram",
                          prepend: "instagram.com/",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.instagram,
                          callback: function ($$v) {
                            _vm.$set(fields, "instagram", $$v)
                          },
                          expression: "fields.instagram",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: {
                          rules: { regex: /^(#)?[a-zA-Z0-9][\w]{2,24}$/ },
                          label: "Twitch",
                          prepend: "twitch.tv/",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.twitch,
                          callback: function ($$v) {
                            _vm.$set(fields, "twitch", $$v)
                          },
                          expression: "fields.twitch",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: {
                          rules: {
                            regex:
                              /^[a-zA-Z0-9\/_]+([\-\.]{1}[a-zA-Z0-9\/_]+)*$/,
                          },
                          label: "YouTube",
                          prepend: "youtube.com/",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.youtube,
                          callback: function ($$v) {
                            _vm.$set(fields, "youtube", $$v)
                          },
                          expression: "fields.youtube",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: {
                          rules: { regex: /^([a-zA-Z0-9._-])+$/ },
                          label: "TikTok",
                          prepend: "@",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.tiktok,
                          callback: function ($$v) {
                            _vm.$set(fields, "tiktok", $$v)
                          },
                          expression: "fields.tiktok",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: {
                          rules: { regex: /^([a-zA-Z0-9.\/_-])+$/ },
                          label: "LinkedIn",
                          prepend: "linkedin.com/in/",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: fields.linkedin,
                          callback: function ($$v) {
                            _vm.$set(fields, "linkedin", $$v)
                          },
                          expression: "fields.linkedin",
                        },
                      }),
                      _vm._v(" "),
                      _c("simple-social", {
                        attrs: { label: "Other" },
                        model: {
                          value: fields.othersocialmedia,
                          callback: function ($$v) {
                            _vm.$set(fields, "othersocialmedia", $$v)
                          },
                          expression: "fields.othersocialmedia",
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              1283815455
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("I plan to attend the convention:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }