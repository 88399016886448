var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex flex-row pb-3" }, [
        _c(
          "div",
          [
            _vm._v(
              "\n      What is the maximum number of program items you are willing to participate in across the entire duration of the convention?"
            ),
            _c("br"),
            _vm._v(" "),
            _vm.calTimeZone
              ? _c("session-limit-editor", {
                  attrs: { timezone: _vm.calTimeZone, model: "session_limit" },
                  model: {
                    value: _vm.person,
                    callback: function ($$v) {
                      _vm.person = $$v
                    },
                    expression: "person",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Under each day, highlight (click and drag) the times of day that you are available for programming in the calendar view below.\n        You can create multiple blocks of time per day. "
              ),
              _vm.eventVirtual
                ? _c("span", [
                    _vm._v(
                      "The in-person convention time is currently displayed.\n        If you will be attending virtually, and want to enter your availability in that time zone,\n        select that option from below the calendar.\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.eventVirtual
                ? _c("span", [
                    _vm._v(
                      "The time is displayed in the convention time zone, which is currently " +
                        _vm._s(_vm.timezone) +
                        "."
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.calTimeZone && _vm.start_time && _vm.end_time
        ? _c("div", { staticClass: "d-flex flex-row" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _c("availability-calendar", {
                  attrs: {
                    days: _vm.days,
                    model: "availability",
                    timezone: _vm.calTimeZone,
                  },
                  model: {
                    value: _vm.person,
                    callback: function ($$v) {
                      _vm.person = $$v
                    },
                    expression: "person",
                  },
                }),
                _vm._v(" "),
                _vm.eventVirtual
                  ? _c(
                      "div",
                      { staticClass: "mt-1 w-50" },
                      [
                        _vm._v(
                          "\n        Select time zone to work in:\n        "
                        ),
                        _c("b-form-select", {
                          attrs: { options: _vm.timeZoneOptions },
                          model: {
                            value: _vm.calTimeZone,
                            callback: function ($$v) {
                              _vm.calTimeZone = $$v
                            },
                            expression: "calTimeZone",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pl-2", staticStyle: { flex: "1" } },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "b-row",
                      { staticClass: "my-1" },
                      [
                        _c("b-col", { attrs: { sm: "12" } }, [
                          _vm._v(
                            "\n            What is the maximum number of program items you are willing to participate in on each day?"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              "(These can add up to more than your convention maximum.)"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.calTimeZone && _vm.days
                  ? _c("session-limits", {
                      attrs: { days: _vm.days, timezone: _vm.calTimeZone },
                      model: {
                        value: _vm.person,
                        callback: function ($$v) {
                          _vm.person = $$v
                        },
                        expression: "person",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-row" },
        [
          _c("exclusions-picker", {
            attrs: { model: "person_exclusion" },
            model: {
              value: _vm.person,
              callback: function ($$v) {
                _vm.person = $$v
              },
              expression: "person",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.editable_person
        ? _c("availability-notes-field", {
            attrs: { model: "person" },
            on: { input: _vm.onNotePatched },
            model: {
              value: _vm.editable_person,
              callback: function ($$v) {
                _vm.editable_person = $$v
              },
              expression: "editable_person",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }