var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "b-alert",
        {
          attrs: {
            show: _vm.alert.visible,
            variant: "success",
            dismissible: "",
          },
        },
        [_vm._v(_vm._s(_vm.alert.text))]
      ),
      _vm._v(" "),
      _c("b-alert", { attrs: { show: _vm.error.visible, variant: "danger" } }, [
        _vm._v(_vm._s(_vm.error.text)),
      ]),
      _vm._v(" "),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("email-field", {
            attrs: { validateNow: _vm.form.email.validate },
            on: {
              validated: function ($event) {
                _vm.form.email.valid = $event
              },
            },
            model: {
              value: _vm.person.email,
              callback: function ($$v) {
                _vm.$set(_vm.person, "email", $$v)
              },
              expression: "person.email",
            },
          }),
          _vm._v(" "),
          _c("login-password-field", {
            attrs: {
              validateNow: _vm.form.password.validate,
              validation: false,
            },
            on: {
              validated: function ($event) {
                _vm.form.password.valid = $event
              },
            },
            model: {
              value: _vm.person.password,
              callback: function ($$v) {
                _vm.$set(_vm.person, "password", $$v)
              },
              expression: "person.password",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pt-3" }, [
            _c(
              "small",
              [
                _vm._v(_vm._s(_vm.LOGIN_CLICK_TO_AGREE) + " "),
                _c("privacy-policy-link"),
                _vm._v("."),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-row-reverse mb-3" },
            [
              _c(
                "router-link",
                { attrs: { to: "/login/forgot?redirect=" + _vm.redirect } },
                [_vm._v("Forgot Password?")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-row-reverse" },
            [
              _c(
                "b-button",
                {
                  staticClass: "px-5",
                  attrs: { type: "submit", variant: "primary" },
                },
                [_vm._v("Log In")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column mb-3" },
        [
          _c(
            "router-link",
            { attrs: { to: "/login/forgot?redirect=" + _vm.redirect } },
            [_vm._v("Never set up your password? Set it up now")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { attrs: { to: "/login/new?redirect=" + _vm.redirect } },
            [_vm._v("Create account")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }