var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("h2", [_vm._v("Basic Information")]),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Interest Instructions" } },
            [
              _c("plano-editor", {
                attrs: { id: "interest-instructions-text", type: "classic" },
                on: {
                  change: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session.instructions_for_interest,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "instructions_for_interest", $$v)
                  },
                  expression: "session.instructions_for_interest",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Areas" } },
            [
              _c("model-select", {
                attrs: {
                  id: "session-areas",
                  model: "area",
                  field: "name",
                  multiple: true,
                },
                on: {
                  change: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session_areas,
                  callback: function ($$v) {
                    _vm.session_areas = $$v
                  },
                  expression: "session_areas",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Format" } },
            [
              _c("model-select", {
                attrs: {
                  id: "session-format",
                  model: "format",
                  field: "name",
                  multiple: false,
                  selectSize: 4,
                },
                on: {
                  change: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session.format_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "format_id", $$v)
                  },
                  expression: "session.format_id",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Session Environment" } },
            [
              _vm.currentSettings && _vm.currentSettings.enums
                ? _c(
                    "b-form-radio-group",
                    {
                      attrs: { id: "session-environment", stacked: "" },
                      on: {
                        change: function ($event) {
                          return _vm.saveSession()
                        },
                      },
                      model: {
                        value: _vm.session.environment,
                        callback: function ($$v) {
                          _vm.$set(_vm.session, "environment", $$v)
                        },
                        expression: "session.environment",
                      },
                    },
                    _vm._l(
                      _vm.currentSettings.enums.Session.environment,
                      function (env) {
                        return _c(
                          "b-form-radio",
                          { key: env, attrs: { value: env } },
                          [_vm._v(_vm._s(_vm.SESSION_ENVIRONMENT[env]))]
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Room Setup" } },
            [
              _c("model-select", {
                attrs: {
                  id: "session-room-setup",
                  model: "room_set",
                  field: "name",
                  multiple: false,
                  selectSize: 4,
                },
                on: {
                  change: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session.room_set_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "room_set_id", $$v)
                  },
                  expression: "session.room_set_id",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "form-inline my-4",
              attrs: { label: "Attendee Signup Required" },
            },
            [
              _c("span", [_vm._v("No")]),
              _vm._v(" "),
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "session-attendee-signup-req",
                    inline: "",
                    switch: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.saveSession()
                    },
                  },
                  model: {
                    value: _vm.session.require_signup,
                    callback: function ($$v) {
                      _vm.$set(_vm.session, "require_signup", $$v)
                    },
                    expression: "session.require_signup",
                  },
                },
                [_vm._v("Yes")]
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  class: [
                    "ml-2",
                    { "text-muted": !_vm.session.require_signup },
                  ],
                },
                [
                  _vm._v("If yes, max openings:\n          "),
                  _c("ValidationProvider", {
                    attrs: { rules: "min_value:1" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (validationCtx) {
                          return [
                            _c("b-form-input", {
                              staticClass: "ml-1",
                              attrs: {
                                id: "session-max-signups",
                                type: "number",
                                disabled: !_vm.session.require_signup,
                                state: _vm.getValidationState(validationCtx),
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.saveValidatedSession(validationCtx)
                                },
                              },
                              model: {
                                value: _vm.session.audience_size,
                                callback: function ($$v) {
                                  _vm.$set(_vm.session, "audience_size", $$v)
                                },
                                expression: "session.audience_size",
                              },
                            }),
                            _vm._v(" "),
                            _c("b-form-invalid-feedback", [
                              _vm._v(_vm._s(validationCtx.errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Attendee Age Restrictions" } },
            [
              _c("b-form-radio-group", {
                attrs: {
                  id: "session-age-restriction",
                  stacked: "",
                  name: "age_restriction",
                  options: _vm.ageRestrictionOptions,
                },
                on: {
                  change: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session.age_restriction_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "age_restriction_id", $$v)
                  },
                  expression: "session.age_restriction_id",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Minors Participation" } },
            [
              _c("b-form-checkbox-group", {
                attrs: {
                  id: "session-minor-participation",
                  stacked: "",
                  options: _vm.minorsParticipationOptions,
                  name: "minors_participation",
                },
                on: {
                  change: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.minors_participation,
                  callback: function ($$v) {
                    _vm.minors_participation = $$v
                  },
                  expression: "minors_participation",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Required Room Features/Services" } },
            [
              _c("b-textarea", {
                attrs: { id: "session-room-feature" },
                on: {
                  blur: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session.room_notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "room_notes", $$v)
                  },
                  expression: "session.room_notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Scheduled Participant Notes" } },
            [
              _c("plano-editor", {
                attrs: { id: "scheduled-participant-text", type: "classic" },
                on: {
                  change: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session.participant_notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "participant_notes", $$v)
                  },
                  expression: "session.participant_notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Tech/Hotel Notes" } },
            [
              _c("b-textarea", {
                attrs: { id: "session-tech-notes" },
                on: {
                  blur: function ($event) {
                    return _vm.saveSession()
                  },
                },
                model: {
                  value: _vm.session.tech_notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "tech_notes", $$v)
                  },
                  expression: "session.tech_notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }