var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("dl", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-6 col-lg-4" }, [
        _c("dt", [_vm._v("Status")]),
        _vm._v(" "),
        _c("dd", { staticClass: "font-italic ml-2" }, [
          _vm._v(
            _vm._s(_vm.PERSON_CON_STATE[_vm.selected.con_state || "not_set"])
          ),
        ]),
        _vm._v(" "),
        _c("dt", [_vm._v("Registered")]),
        _vm._v(" "),
        _c("dd", { staticClass: "font-italic ml-2" }, [
          _vm._v(_vm._s(_vm.selected.registered ? "Yes" : "No")),
        ]),
        _vm._v(" "),
        _c("dt", [_vm._v("Registration ID")]),
        _vm._v(" "),
        _c("dd", { staticClass: "font-italic ml-2" }, [
          _vm._v(_vm._s(_vm.selected.registration_number || "Unknown")),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6 col-lg-4" }, [
        _c("dt", [_vm._v("Convention Class")]),
        _vm._v(" "),
        _c("dd", { staticClass: "font-italic ml-2" }, [
          _vm._v(_vm._s(_vm.conventionClasses)),
        ]),
      ]),
      _vm._v(" "),
      _c("dt", { staticClass: "col-12 mt-2" }, [_vm._v("Comments")]),
      _vm._v(" "),
      _c(
        "dd",
        { staticClass: "col-12" },
        [
          _c("b-form-textarea", {
            model: {
              value: _vm.comments,
              callback: function ($$v) {
                _vm.comments = $$v
              },
              expression: "comments",
            },
          }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "float-right mt-1",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.patchSelected({ comments: _vm.comments })
                },
              },
            },
            [_vm._v("Save Comments")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }