var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("icon-button", {
    attrs: { icon: "pencil-fill", background: "none", title: _vm.title },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }