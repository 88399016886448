var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "link" },
                on: {
                  click: function ($event) {
                    return _vm.allButton()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.anyOpen ? "Collapse all" : "Show all"))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          class: { "col-8": !_vm.noSidebar, "col-12": _vm.noSidebar },
          style: _vm.heightHelper,
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.loading,
                "spinner-variant": "primary",
                variant: "white",
                opacity: "1",
              },
            },
            [
              _vm._l(_vm.orderedSessions, function (session) {
                return _c(
                  "schedule-collapse",
                  {
                    key: session.id,
                    attrs: { id: session.id },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("span", { staticClass: "larger-text" }, [
                                _c("strong", { staticClass: "larger-text" }, [
                                  _vm._v(_vm._s(session.title)),
                                ]),
                                _vm._v(
                                  ", " +
                                    _vm._s(_vm.formatStartTime(session)) +
                                    ", " +
                                    _vm._s(session.room)
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.open[session.id],
                      callback: function ($$v) {
                        _vm.$set(_vm.open, session.id, $$v)
                      },
                      expression: "open[session.id]",
                    },
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "dl",
                      { staticClass: "indented-dl" },
                      [
                        _c("dt", [_vm._v("Title")]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(session.title))]),
                        _vm._v(" "),
                        _c("dt", [
                          _vm._v(
                            "Participants (with contact information where allowed)"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(session.moderators, function (p) {
                          return _c("dd", { key: p.published_name }, [
                            _vm._v(
                              _vm._s(p.published_name) +
                                " (m) " +
                                _vm._s(p.pronouns) +
                                " - " +
                                _vm._s(
                                  p.email ? p.email : "permission not given"
                                )
                            ),
                          ])
                        }),
                        _vm._v(" "),
                        _vm._l(session.participants, function (p) {
                          return _c("dd", { key: p.published_name }, [
                            _vm._v(
                              _vm._s(p.published_name) +
                                " " +
                                _vm._s(p.pronouns) +
                                " - " +
                                _vm._s(
                                  p.email ? p.email : "permission not given"
                                )
                            ),
                          ])
                        }),
                        _vm._v(" "),
                        _vm._l(session.invisibles, function (p) {
                          return _c("dd", { key: p.published_name }, [
                            _vm._v(
                              _vm._s(p.published_name) +
                                " (i) " +
                                _vm._s(p.pronouns) +
                                " - " +
                                _vm._s(
                                  p.email ? p.email : "permission not given"
                                )
                            ),
                          ])
                        }),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Description")]),
                        _vm._v(" "),
                        _c("dd", {
                          domProps: { innerHTML: _vm._s(session.description) },
                        }),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Space/Time")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            _vm._s(session.room) +
                              " " +
                              _vm._s(_vm.formatStartTime(session))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Duration")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(_vm._s(session.duration) + " minutes"),
                        ]),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Session Environment")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            _vm._s(_vm.SESSION_ENVIRONMENT[session.environment])
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Session Format")]),
                        _vm._v(" "),
                        _c("dd", { staticClass: "not-italic" }, [
                          _c(
                            "span",
                            { staticClass: "badge badge-pill badge-info" },
                            [_vm._v(_vm._s(session.format))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Session Area(s)")]),
                        _vm._v(" "),
                        session.areas.length
                          ? _c(
                              "dd",
                              { staticClass: "not-italic" },
                              _vm._l(session.areas, function (area) {
                                return _c(
                                  "span",
                                  {
                                    key: area,
                                    staticClass:
                                      "badge badge-pill badge-primary",
                                  },
                                  [_vm._v(_vm._s(area))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !session.areas.length
                          ? _c("dd", { staticClass: "text-muted" }, [
                              _vm._v("None Selected"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("dt", [_vm._v("Schedule Notes")]),
                        _vm._v(" "),
                        _c("dd", {
                          domProps: {
                            innerHTML: _vm._s(session.participant_notes),
                          },
                        }),
                      ],
                      2
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.noSessions
                ? _c("div", { staticClass: "p-5 text-muted font-italic" }, [
                    _vm._v(
                      "There are not currently any sessions for this participant."
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.noSidebar
        ? _c(
            "div",
            { staticClass: "col-4" },
            [
              _vm._t("message"),
              _vm._v(" "),
              _c("person-schedule-approval", {
                attrs: { approvalType: _vm.approvalType },
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }