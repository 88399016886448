var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.session
    ? _c("div", { staticClass: "pb-3" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "w-75" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mx-3",
                  attrs: {
                    id: "session-title-group",
                    label: "Session Title",
                    "label-for": "session-title",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "session-title", type: "text" },
                    on: {
                      blur: function ($event) {
                        return _vm.saveSession()
                      },
                    },
                    model: {
                      value: _vm.session.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.session, "title", $$v)
                      },
                      expression: "session.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mx-3",
                  attrs: {
                    id: "session-description-group",
                    label: "Session Description",
                    "label-for": "session-description",
                  },
                },
                [
                  _c("plano-editor", {
                    attrs: {
                      id: "session-description",
                      type: "classic",
                      height: 100,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.saveSession()
                      },
                    },
                    model: {
                      value: _vm.session.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.session, "description", $$v)
                      },
                      expression: "session.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { staticClass: "mx-3" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "session-open-for-interest", switch: "" },
                      on: {
                        change: function ($event) {
                          return _vm.saveSession()
                        },
                      },
                      model: {
                        value: _vm.session.open_for_interest,
                        callback: function ($$v) {
                          _vm.$set(_vm.session, "open_for_interest", $$v)
                        },
                        expression: "session.open_for_interest",
                      },
                    },
                    [
                      _vm._v("\n          Open for Interest\n          "),
                      _vm.session.open_for_interest
                        ? _c("span", [
                            _vm._v(
                              "\n            on " +
                                _vm._s(
                                  _vm.session.interest_opened_at
                                    ? new Date(
                                        _vm.session.interest_opened_at
                                      ).toLocaleString()
                                    : "n/a"
                                ) +
                                "\n            by " +
                                _vm._s(
                                  _vm.session.interest_opened_by
                                    ? _vm.session.interest_opened_by.toLocaleString()
                                    : "n/a"
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.session
            ? _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c("small", { staticClass: "text-muted d-block" }, [
                    _vm._v("\n        Last edited by:\n        "),
                    _c("em", [_vm._v(_vm._s(_vm.session.updated_by))]),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted d-block" }, [
                    _vm._v("\n        Last edited on:\n        "),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          new Date(_vm.session.updated_at).toLocaleString()
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { id: "session-proofed", switch: "" },
                          on: {
                            change: function ($event) {
                              return _vm.saveSession()
                            },
                          },
                          model: {
                            value: _vm.session.proofed,
                            callback: function ($$v) {
                              _vm.$set(_vm.session, "proofed", $$v)
                            },
                            expression: "session.proofed",
                          },
                        },
                        [_vm._v("Copy Edited/Proofed")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "session-scheduled",
                            switch: "",
                            disabled: "",
                            checked: _vm.scheduled,
                          },
                        },
                        [_vm._v("Scheduled")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-3",
                      attrs: { label: "Public Schedule Visibility" },
                    },
                    [
                      _c("span", [_vm._v("Not Visible")]),
                      _vm._v(" "),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            id: "session-public-schedule-visibility",
                            switch: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.saveSession()
                            },
                          },
                          model: {
                            value: _vm.visibility,
                            callback: function ($$v) {
                              _vm.visibility = $$v
                            },
                            expression: "visibility",
                          },
                        },
                        [_vm._v("Visible")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { id: "session-recorded", switch: "" },
                          on: {
                            change: function ($event) {
                              return _vm.saveSession()
                            },
                          },
                          model: {
                            value: _vm.session.recorded,
                            callback: function ($$v) {
                              _vm.$set(_vm.session, "recorded", $$v)
                            },
                            expression: "session.recorded",
                          },
                        },
                        [_vm._v("Will be recorded")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { id: "session-streamed", switch: "" },
                          on: {
                            change: function ($event) {
                              return _vm.saveSession()
                            },
                          },
                          model: {
                            value: _vm.session.streamed,
                            callback: function ($$v) {
                              _vm.$set(_vm.session, "streamed", $$v)
                            },
                            expression: "session.streamed",
                          },
                        },
                        [_vm._v("Will be livestreamed")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Status", "label-cols": "auto" } },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: { id: "session-status" },
                          on: {
                            change: function ($event) {
                              return _vm.saveSession()
                            },
                          },
                          model: {
                            value: _vm.session.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.session, "status", $$v)
                            },
                            expression: "session.status",
                          },
                        },
                        _vm._l(
                          _vm.currentSettings.enums.Session.status,
                          function (status) {
                            return _c(
                              "b-form-select-option",
                              {
                                key: status,
                                attrs: {
                                  value: status,
                                  title:
                                    _vm.scheduled && status === "dropped"
                                      ? _vm.SESSION_MUST_UNSCHEDULE
                                      : "",
                                  disabled:
                                    _vm.scheduled && status === "dropped",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.SESSION_STATUS[status]) +
                                    "\n          "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentUserIsAdmin && _vm.airmeetEnabled
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            variant: "warning",
                            disabled: !_vm.session.streamed,
                          },
                          on: { click: _vm.resyncAirmeet },
                        },
                        [_vm._v("Airmeet re-sync completed")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }