var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-10" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-row justify-content-between" },
      [
        _c(
          "b-link",
          {
            staticClass: "mr-2",
            on: {
              click: function ($event) {
                return _vm.showPersonDetails(_vm.assignee)
              },
            },
          },
          [_vm._v(_vm._s(_vm.assignee.published_name))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mr-2" }, [
          _vm._v("\n      Rank: "),
          _c("em", [_vm._v(_vm._s(_vm.rank))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mr-2" }, [
          _vm._v("\n      Default moderation preference: "),
          _c("span", { staticClass: "first-capital" }, [
            _c("em", [_vm._v(_vm._s(_vm.defaultModPreference))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mr-2" }, [
          _vm._v("\n      Session moderation preference: "),
          _c("span", { staticClass: "first-capital" }, [
            _c("em", [_vm._v(_vm._s(_vm.sessionModPreference))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mr-2" }, [
          _vm._v("\n      Attendance type: "),
          _c("em", [
            _vm._v(
              _vm._s(
                _vm._f("na_if_empty")(
                  _vm._f("capitalize")(_vm.assignee.attendance_type)
                )
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mr-2" }, [
          _vm._v("\n      Accessibility concerns: "),
          _c("em", [
            _vm._v(_vm._s(_vm.assignee.needs_accommodations ? "Y" : "N")),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-2" }, [
      _vm.noInterestExpressed
        ? _c("div", [_c("em", [_vm._v("Did not express interest")])])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _vm._v("Status: "),
        _c("em", [_vm._v(_vm._s(_vm.assignee.con_state))]),
      ]),
      _vm._v(" "),
      _c("h6", [_vm._v("Session comments")]),
      _vm._v(" "),
      _vm.assignment
        ? _c("div", { staticClass: "ml-2 keep-format" }, [
            _vm._v(
              _vm._s(_vm._f("na_if_empty")(_vm.assignment.interest_notes))
            ),
          ])
        : _c("div", { staticClass: "ml-2" }, [_vm._v("N/A")]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-2" }, [
      _c("h6", [_vm._v("Other demographic categories")]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-2" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm._f("na_if_empty")(_vm.assignee.demographic_categories)) +
            "\n    "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }