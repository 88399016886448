var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dl",
    [
      _vm._l(_vm.fields, function (field, i) {
        return [
          _c(
            "dt",
            { key: "dt-" + i },
            [
              _vm._t(field + "-label", function () {
                return [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.PROFILE_FIELD_LABELS[field]) +
                      "\n        "
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "dd",
            { key: "dd-" + i, staticClass: "ml-2 font-italic" },
            [
              _vm._t(
                field + "-val",
                function () {
                  return [
                    _vm.selected[field] === undefined
                      ? _c("span", { staticClass: "text-muted" }, [
                          _vm._v("Restricted"),
                        ])
                      : _vm.selected[field] === true
                      ? _c("span", [_vm._v("Yes")])
                      : _vm.selected[field] === false
                      ? _c("span", [_vm._v("No")])
                      : _vm.selected[field] === null ||
                        _vm.selected[field].trim().length === 0
                      ? _c("span", { staticClass: "text-muted" }, [
                          _vm._v("Not Specified"),
                        ])
                      : _c("span", { staticClass: "keep-format" }, [
                          _vm._v(_vm._s(_vm.selected[field])),
                        ]),
                  ]
                },
                { value: _vm.selected[field] }
              ),
            ],
            2
          ),
        ]
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }